
export namespace AuthActions{
  export class Loading {
    static readonly type = '[Auth] Set As Loading';
  }

  export class Working {
    static readonly type = '[Auth] Set As Working';
  }

  export class Done {
    static readonly type = '[Auth] Set As Done';
  }
  export class ApplyUserSession {
    static readonly type = '[Auth] Apply User Session';
  }
  export class Login{
    static readonly type = '[Auth] Login';
  }
  export class LogOut{
    static readonly type = '[Auth] LogOut';
  }

  export class OtrLeasingToken {
    static readonly type = '[Auth] OtrLeasingToken';
  }
}
