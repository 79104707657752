<h1>Send Verification Code</h1>

<form class="example-form" [formGroup]="data" (ngSubmit)="onSubmit()">

  <div class="row justify-content-md-center mt-5">
    <div class="col col-lg-7 col-xl-6">

      <mat-label>Phone#</mat-label>
      <mat-form-field class="example-full-width">
        <input matInput placeholder="(___)___-____" formControlName="phone" [mask]="maskPhonePattern" readonly>
        <mat-error>You must enter a valid value</mat-error>
      </mat-form-field>

    </div>

  </div>

  <div class="row justify-content-md-center mt-4">
    <div class="col col-lg-7 col-xl-6">
      <button mat-stroked-button color="primary" type="submit">Generate Verification Code</button>
    </div>
  </div>

</form>
