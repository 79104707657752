import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {IAuthStateModel, IOtrTokenModel} from './auth.model';
import {AuthActions} from './auth.actions';
import {catchError, filter, map, mergeMap, tap, throwError} from 'rxjs';
// import {AuthService} from "@auth0/auth0-angular";
import { isPlatformBrowser } from "@angular/common";
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';

@State<IAuthStateModel>({
  name: 'auth',
  defaults: <IAuthStateModel>{
    loading: true,
    busy: false,
  }
})
@Injectable()
export class AuthState implements NgxsOnInit {
  baseUrl: string;
  authorizedKey: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    //private readonly authService: AuthService,
    private store: Store,
    private http: HttpServiceService, ) {
      this.baseUrl = environment.applynow.base_uri;
      this.authorizedKey = environment.lease_path.key;
   
  }
  ngxsOnInit(ctx: StateContext<any>): void {
    if(isPlatformBrowser(this.platformId)){

      // this.authService.isLoading$.pipe(
      //   filter(isLoading => !isLoading),
      //   mergeMap(() => ctx.dispatch(new AuthActions.Done()))
      // ).subscribe()

      // this.authService.isAuthenticated$.pipe(
      //   filter(isAuthenticated => isAuthenticated),
      //   mergeMap(() => {
      //     return ctx.dispatch(new AuthActions.ApplyUserSession());
      //   })
      // ).subscribe();
    }

    this.store.dispatch(new AuthActions.OtrLeasingToken());
  }

  @Selector()
  static IsLoading(state: IAuthStateModel): boolean {
    return state.loading;
  }
  @Selector()
  static IsDone(state: IAuthStateModel): boolean {
    return !state.loading;
  }

  @Selector()
  static IsWorking(state: IAuthStateModel): boolean {
    return state.busy;
  }
  @Selector()
  static CurrentUser(state: IAuthStateModel): any {
    //return state.user;
  }

  @Action(AuthActions.Done)
  onDone(ctx: StateContext<IAuthStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(AuthActions.Loading)
  onLoading(ctx: StateContext<IAuthStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(AuthActions.Working)
  onWorking(ctx: StateContext<IAuthStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(AuthActions.ApplyUserSession)
  onApplyUserSession(ctx: StateContext<IAuthStateModel>) {

    // return this.authService.user$.pipe(
    //   tap(user => {
    //     ctx.patchState({
    //       user: user
    //     });
    //   })
    // );
  }
  @Action(AuthActions.Login)
  onLogin(ctx: StateContext<IAuthStateModel>) {
    // return this.authService.loginWithRedirect(
    //   {
    //     authorizationParams:{
    //       redirect_uri: window.location.origin
    //     }
    //   }
    // );
  }
  @Action(AuthActions.LogOut)
  onLogOut(ctx: StateContext<IAuthStateModel>) {
    // return this.authService.logout(
    //   {
    //     logoutParams:{
    //       returnTo: window.location.origin
    //     }
    //   }
    // );
  }

  @Selector()
  static getOtrToken(state: IOtrTokenModel): any {
    return state.applicationToken;
  }

  @Action(AuthActions.OtrLeasingToken)
  getOtrLeasingToken(ctx: StateContext<IOtrTokenModel>) {
    let token_url = `${this.baseUrl}/Token`;

    return this.http.postData(token_url, { authorizedKey: this.authorizedKey }).pipe(
      map((response: IOtrTokenModel) => {
        ctx.patchState({
            applicationToken:response.applicationToken
        });
      }),
      catchError((error) => {
        console.error('Error while fetching token:', error);
        return throwError(error);
      })
    );
  }

}
