import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from '../../../../../services/common.service';


@Component({
  selector: 'app-apply-now-q1',
  templateUrl: './apply-now-q1.component.html',
  styleUrls: ['./apply-now-q1.component.css']
})
export class ApplyNowQ1Component {
  constructor(private router: Router, private ngxService: NgxUiLoaderService, private commonService: CommonService
  ) {
    this.commonService.sendUpdate('0%');
    //this.commonService.openSnackBar('this is for testing', '', 'success');
  }

  onAnswerClick(value: any) {
    this.commonService.applyNowData.cdlOwnerShip = value ? false : true;

    if (this.commonService.applyNowData.cdlOwnerShip) {
      this.commonService.applyNowData.cdlLength = true;
    }

    this.routerComment(value);
  }

  routerComment(value: any) {
    // this.router.navigateByUrl('/ApplyNowQ2');
    let routerUrl = '/ApplyNowQ3';
    if (value == true)
      routerUrl = '/ApplyNowQ2';

    this.router.navigate([routerUrl], {
      skipLocationChange: true,
    });
  }

}
