import { Component } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'OTRLeasing.Apply';

  //gtmId: string = environment.google.gtm_code;

  ngOnInit() {
    //this.loadGtm();
  }


  //loadGtm() {
  //  const gtmCode = this.gtmId;
  //  const script = document.createElement('script');
  //  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmCode}');`;
  //  document.head.appendChild(script);

  //  const noscript = document.createElement('noscript');
  //  noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmCode}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  //  document.body.appendChild(noscript);
  //}

}
