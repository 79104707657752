import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { Observable, catchError, tap, throwError, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApplyStateModel} from './apply.model';
import { AddApplyNow, VerifyCode, sendPin } from './apply.actions';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { HttpHeaders } from '@angular/common/http';
import { AuthActions } from '../auth/auth.actions';

export interface ApplyNowStateModel {
  applyNow: ApplyStateModel | null;
}
export interface IsendPin {
  sendPin: any | null;
}
export interface IVerifyCode {
  verifyCode: any | null;
}

@Injectable()
@State<ApplyNowStateModel>({
  name: 'applyNow',
  defaults: {
    applyNow: null
  }
})

export class ApplyNowState {
  baseUrl: string;
  constructor(private http: HttpServiceService,
    private common: CommonService,
    private store: Store,) {
    this.baseUrl = environment.applynow.base_uri;
  }


  @Action(AddApplyNow)
  addApply(ctx: StateContext<ApplyNowStateModel>, action: AddApplyNow): Observable<any> {

    let url = `${this.baseUrl}integration/LeasePath`;

    return this.http.postDataWithToken(url, action.payload).pipe(
      tap((response: ApplyStateModel) => {
        ctx.setState({
          applyNow: response
        });
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }


  @Action(sendPin)
  sendPin(ctx: StateContext<IsendPin>, action: sendPin): any {
    // let url = `${this.baseUrl}integration/Twilio/code/send?phoneNumber=${action.payload}`;
    let url = `${this.baseUrl}integration/Twilio/code/send`;
    return this.http.postDataWithToken(url, action.payload).pipe(
      tap((response: any) => {
        ctx.setState({
          sendPin: response
        });
      }),
      catchError((error) => {
        //alert('Error occurred: ' + error);
        return throwError(error);
      })
    );

  }
  @Action(VerifyCode)
  verifyCode(ctx: StateContext<IVerifyCode>, action: VerifyCode): any {
    // let url = `${this.baseUrl}integration/Twilio/code/verify?phoneNumber=${action.phoneNumber}&code=${action.code}`;
    let url = `${this.baseUrl}integration/Twilio/code/verify`;
    return this.http.postDataWithToken(url,
      {
        'phoneNumber': action.phoneNumber,
        'code': action.code,
        'leadId': action.leadId
      }).pipe(
        tap((response: any) => {
          ctx.setState({
            verifyCode: response
          });
        }),
        catchError((error) => {
          //alert('Error occurred: ' + error);
          return throwError(error);
        })
      );
  }
 

}
