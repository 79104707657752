<div class="container-fluid">

  <app-slider></app-slider>

  <app-progress-bar></app-progress-bar>

  <!-- Forms -->
  <div class="row justify-content-md-center from-container-row">
    <div class="col-xl-7 col-lg-10 col-md-12 col-sm-12">
      <div class="form-container">
        <router-outlet />
      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>

