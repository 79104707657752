import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider/slider.component';
import { ProgressBarComponent } from '../layout/progress-bar/progress-bar.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';

import { RouterModule, Routes } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatIconModule } from '@angular/material/icon';
import { ApplyNowModule } from '../views/main/pages/apply-now/apply-now.module';
import { CarouselModule } from 'ngx-owl-carousel-o';

const routes: Routes = [
  { path: '', redirectTo: '/apply-now', pathMatch: 'full' },
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', loadChildren: () => ApplyNowModule }
    ]
  }
]

@NgModule({
  declarations: [
    SliderComponent,
    ProgressBarComponent,
    FooterComponent,
    LayoutComponent
  ],
  imports: [
    CommonModule,
    NgxUiLoaderModule,
    MatIconModule,
    RouterModule.forChild(routes),
    CarouselModule 
  ]
})
export class LayoutModule { }
