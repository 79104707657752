import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from '../../../../../services/common.service';

@Component({
  selector: 'app-apply-now-thanks',
  templateUrl: './apply-now-thanks.component.html',
  styleUrls: ['./apply-now-thanks.component.css']
})
export class ApplyNowThanksComponent {
  leadId: any;
  isRouteFromDocumentScreen: boolean;
  constructor(private router: Router, private ngxService: NgxUiLoaderService
     , private commonService: CommonService
    ) {

   // let url = window.location.href;
    //this.leadId = this.commonService.getUrlParameter('leadId', url);
    this.commonService.sendUpdate('calc(100% - 100px)');
    this.isRouteFromDocumentScreen = this.commonService.isRouteFromDocumentScreen;
  }



  routerComment() {
    this.router.navigate(['/documents/upload'], {
      queryParams: { id: this.commonService.leadId }
    });
    //this.router.navigate(['/ApplyNowSendCode'], {
    //  skipLocationChange: true,
    //});
  }
}
