
export class ApplyStateModel {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string | null;
  mobilePhone?: string;
  cdlOwnerShip?: boolean;
  cdlLength?: boolean;
  llcOwnerShip?: boolean;
  llcLength?: number | null;
  fleetSize?: string;
  address1?: string;
  address2?: string;
  state?: string;
  city?: string;
  postalcode?: string;
  email?: string;
  ssn?: string;
  carrierName?: string;
  expectedFreightType?: string;
  teamDrive?: boolean;
  marketingSource?: string;
  expectedRevene?: number;
  expectedMileage?: number | null;
  truckId?: number;

  leadId?: string = "";

  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmTerm?: string;

  customerResponse: any;
  opportunity?: Opportunity;

  gtmClientID: string;

}



export class Opportunity {
  processStageName?:string;
  opportunityId?: string;
}

