import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  constructor(private router: Router, private route: ActivatedRoute) {

  }

  handleClick(event: MouseEvent, path: any) {

    this.routerComment(path);
    
    // Get the clicked element
    const clickedElement = event.target as HTMLElement;
    let parentElement = clickedElement.parentElement;

    const siblings = this.getSiblings(parentElement);
    // Remove class from sibling elements
    siblings.forEach(sibling => {
      sibling.classList.remove('completed');
    });
    
    parentElement?.classList.add('completed');
    // Add class to all previous siblings
    let pElement = clickedElement.parentElement as HTMLElement;
    let sibling = pElement.previousElementSibling;
    while (sibling) {
      sibling.classList.add('completed');
      sibling = sibling.previousElementSibling;
    }
  }
  
  private getSiblings(elem: HTMLElement | null): HTMLElement[] {
    const siblings: HTMLElement[] = [];
    let sibling = elem?.parentNode?.firstChild;
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling as HTMLElement);
      }
      sibling = sibling.nextSibling;
    }
    return siblings;
  }


  //@HostListener('document:keydown', ['$event'])
  //handleKeyboardEvent(event: KeyboardEvent) {
  //  let pathComponent = '';
  //  this.route.url.subscribe(url => { pathComponent = url[0]?.path; });

  //  if (event.key === 'ArrowLeft') {

  //    this.routerComment('/apply-now');
  //  }
  //  else if (event.key === 'ArrowRight') {
  //    this.routerComment('/ApplyNowQ2');

  //  }
  //}

  routerComment(routerVal: string) {
    this.router.navigate([routerVal], {
      skipLocationChange: true,
    });
  }

}
