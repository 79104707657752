import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from '../../../../../services/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-apply-now-q4',
  templateUrl: './apply-now-q4.component.html',
  styleUrls: ['./apply-now-q4.component.css']
})
export class ApplyNowQ4Component {

  constructor(private router: Router, private ngxService: NgxUiLoaderService
     , private commonService: CommonService
    ) {
    this.commonService.sendUpdate('45%');
  }

  data = new FormGroup({
    llcLength: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.max(100)])
  });

  onSubmit() {
    if (this.data.valid) {
      this.commonService.applyNowData.llcLength = parseInt(this.data.controls['llcLength'].value as string);
      this.routerComment();
    }
    else {
      this.data.markAllAsTouched();
    }
  }

  routerComment() {
    this.router.navigate(['/ApplyNowQ5'], {
      skipLocationChange: true,
    });
  }

}
