import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDateMask]'
})
export class DateMaskDirective {

  private maxLength = 10; // MM/DD/YYYY format

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (value) {
      let formattedValue = value.replace(/\D/g, '');
      if (formattedValue.length > 2) {
        formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
      }
      if (formattedValue.length > 5) {
        formattedValue = `${formattedValue.slice(0, 5)}/${formattedValue.slice(5)}`;
      }
      formattedValue = formattedValue.slice(0, this.maxLength); // Trim to max length
      this.ngControl.valueAccessor?.writeValue(formattedValue);
      this.el.nativeElement.value = formattedValue;
    }
  }

}
