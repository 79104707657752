export class Truck {
  id: Number;
  name: string | null | undefined;
  type: string | null | undefined;
  make: string | null | undefined;
  model: string | null | undefined;
  year: string | null | undefined;
  originalMileage: string | null | undefined;
  amount: string | null | undefined;
}
