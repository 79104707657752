<div class="row justify-content-md-center mt-4">
  <div class="col col-lg-12 text-center">
    <img src="assets/images/correct.png" class="progress-truck-img" alt="..." height="100">
  </div>
</div>

<h1 class="mt-4">Thanks for Submitting!</h1>

<div class="row justify-content-md-center mt-3">
  <div class="col col-lg-8">
    <p class="mt-3 text-center">Our team will get in touch with you. If you would like to expedite the process, please click on the link below to upload your documents.</p>
  </div>
</div>

<div *ngIf="!isRouteFromDocumentScreen" class="row justify-content-md-center mt-3 text-center">
  <div class="col col-lg-6">
    <!--<a href="https://otrleasing.com/documents/7518103" target="_blank" class="">Click here to upload documents</a>-->
    <p class="custom-link" (click)="routerComment()">Click here to upload documents</p>
  </div>
</div>
