import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Truck } from '../../../../../../dto/truck';


@Component({
  selector: 'app-select-truck-dialog',
  templateUrl: './select-truck-dialog.component.html',
  styleUrls: ['./select-truck-dialog.component.css']
})
export class SelectTruckDialogComponent {

  //trucklist: Truck[] = [
  //  { id: 1, name: 'test', miles: '200', amount: '2300' },
  //  { id: 2, name: 'test2', miles: '300', amount: '100' }
  //]

  constructor(
    public dialogRef: MatDialogRef<SelectTruckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Truck,
  ) {

    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

 

}
