<h1>How Long Have You Had It?</h1>

<div class="row justify-content-md-center mt-2">
  <div class="col col-lg-12">

    <div class="question-content">
      <div class="questions">
        <div class="question d-inline-flex">
          <div class="question-container" (click)="onAnswerClick(true)">
            <button type="button" value="Yes" data-field-id="choice_4_13_0" data-slide-id="02" data-slide="multi-step-form-slide02" data-calculator="0" class="question-link" aria-controls="#multi-step-form-slide02">
              <div class="question-circle">
                <h5 class="circle-inner-txt orange"><3</h5>
              </div>
              <p class="question-answer orange">
                Less Than <br />
                Three Years
              </p>
            </button>
          </div>
        </div>
        <div class="question d-inline-flex">
          <div class="question-container" (click)="onAnswerClick(false)">
            <button type="button" value="No" data-field-id="choice_4_13_1" data-slide-id="03" data-slide="multi-step-form-slide03" data-calculator="0" class="question-link" aria-controls="#multi-step-form-slide03">
              <div class="question-circle question-circle-blue">
                <h5 class="circle-inner-txt blue">>3</h5>
              </div>
              <p class="question-answer blue">
                More Than <br />
                Three Years
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

