

export class GetTruckById {
  static readonly type = '[Truck] Get Truck by ID';
  constructor(public id: number) {}
}


export class GetTrucks {
  static readonly type = '[Truck] Get Trucks';
}
