import { Action, State, StateContext } from '@ngxs/store';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { GetLead } from './leads.actions';
import { Injectable } from '@angular/core';
import { LeadsStateModel } from './leads.model';

export interface ILeadsStateModel {
  leads: LeadsStateModel | null;
}

@Injectable()
@State<ILeadsStateModel>({
  name: 'leads',
  defaults: {
    leads: null
  }
})

export class LeadsState {
  baseUrl: string;
  constructor(private http: HttpServiceService,
    private common: CommonService) {
    this.baseUrl = environment.applynow.base_uri;
  }




  @Action(GetLead)
  getLead(ctx: StateContext<ILeadsStateModel>, action: GetLead) {
    let url = `${this.baseUrl}integration/LeasePath/leads/${action.id}`;
    return this.http.GetByIdWithToken(url).pipe(
      tap((response: any) => {
        ctx.setState({ leads: response });
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

}
