<div class="how-many-truck-do-u-operate-container">

  <h1>How Many Trucks Do You Operate?</h1>

  <div class="row justify-content-md-center mt-2">
    <div class="col col-lg-12">

      <div class="question-content">
        <div class="questions minimum-magrin">
          <div class="question d-inline-flex">
            <div class="question-container" (click)="onAnswerClick('0-1')">
              <button type="button" value="Yes" data-field-id="choice_4_13_0" data-slide-id="02" data-slide="multi-step-form-slide02" data-calculator="0" class="question-link" aria-controls="#multi-step-form-slide02">
                <div class="question-circle">
                  <h5 class="circle-inner-txt orange">0-1</h5>
                </div>
                <p class="question-answer orange">
                  Trucks
                </p>
              </button>
            </div>
          </div>
          <div class="question d-inline-flex">
            <div class="question-container" (click)="onAnswerClick('2-5')">
              <button type="button" value="No" data-field-id="choice_4_13_1" data-slide-id="03" data-slide="multi-step-form-slide03" data-calculator="0" class="question-link" aria-controls="#multi-step-form-slide03">
                <div class="question-circle">
                  <h5 class="circle-inner-txt orange">2-5</h5>
                </div>
                <p class="question-answer orange">
                  Trucks
                </p>
              </button>
            </div>
          </div>
          <div class="question d-inline-flex">
            <div class="question-container" (click)="onAnswerClick('6-10')">
              <button type="button" value="No" data-field-id="choice_4_13_1" data-slide-id="03" data-slide="multi-step-form-slide03" data-calculator="0" class="question-link" aria-controls="#multi-step-form-slide03">
                <div class="question-circle">
                  <h5 class="circle-inner-txt orange">6-10</h5>
                </div>
                <p class="question-answer orange">
                  Trucks
                </p>
              </button>
            </div>
          </div>
          <div class="question d-inline-flex">
            <div class="question-container" (click)="onAnswerClick('11+')">
              <button type="button" value="No" data-field-id="choice_4_13_1" data-slide-id="03" data-slide="multi-step-form-slide03" data-calculator="0" class="question-link" aria-controls="#multi-step-form-slide03">
                <div class="question-circle">
                  <h5 class="circle-inner-txt orange">11+</h5>
                </div>
                <p class="question-answer orange">
                  Trucks
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>


