import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";

import { FormControl, FormGroup } from '@angular/forms';
import { CommonService } from '../../../../../services/common.service';

import { Store } from '@ngxs/store';
import { UploadDocuments } from '../../../../../states/upload-documents/upload-documents.actions';
import { FileDTO } from '../../../../../states/upload-documents/upload-documents.model';


@Component({
  selector: 'app-apply-now-upload-document',
  templateUrl: './apply-now-upload-document.component.html',
  styleUrls: ['./apply-now-upload-document.component.css'],
})
export class ApplyNowUploadDocumentComponent {
  fileContent: string | ArrayBuffer | null = null;
  fileUrl: string | null = null;
  filesData: any;
  cdlFiles: {
    category: string;
    name: string;
    type: string;
    dataUrl?: string;
  }[] = [];
  controlName: string = '';

  leadId: any;

  documents: FileDTO[] = [];
  firstName: string = 'Jack';

  step: number = 0;

  @ViewChild('fileInput') fileInput: ElementRef;

  cdlExpiredOrCurrentList: string[] = ['CDL - Expired', 'CDL - Current'];
  currentBankStatementList: string[] = [
    'Bank Statement - Business',
    'Bank Statement - Personal',
  ];
  selectedFiles: File[] = [];

  constructor(
    private router: Router,
    private spinner: NgxUiLoaderService,
    private commonService: CommonService,
    private store: Store
  ) {

    let url = window.location.href;
    this.leadId = this.commonService.getUrlParameter('id', url);
    if (this.commonService.leadData)
      this.firstName = this.commonService.leadData?.firstName;
    if (this.commonService.token === "") {
      let currentUrl = this.router.url;
      let paramsIndex = currentUrl.indexOf('?');
      let formattedParams = '';
      if (paramsIndex !== -1) {
        formattedParams = `/otp?id=${this.leadId}`;
      }
      if (formattedParams === "") {
        this.routerComment("/otp");
      }
      else {
        this.routerComment(formattedParams);
      }
    }
    else {
      this.commonService.token = "";
    }
    this.commonService.sendUpdate('calc(60%)');

    this.BindStepper(this.commonService.leadData?.opportunity?.processStageName);

    //this.commonService.openSnackBar('Upload Documents Successfully.','Cencel');

  }

  BindStepper(status: any) {
    if (status?.trim()?.toLowerCase() === 'applied')
      this.step = 1;
    else if (status?.trim()?.toLowerCase() === 'contacted')
      this.step = 2;
    else if (status?.trim()?.toLowerCase() === 'need documents')
      this.step = 3;
    else if (status?.trim()?.toLowerCase() === 'credit submitted')
      this.step = 4;
    else if (status?.trim()?.toLowerCase() === 'credit approved')
      this.step = 5;
    else if (status?.trim()?.toLowerCase() === 'deposit secured')
      this.step = 6;
    else if (status?.trim()?.toLowerCase() === 'documents sent')
      this.step = 7;
    else if (status?.trim()?.toLowerCase() === 'seated')
      this.step = 8;
    else
      this.step = 1;
  }

  routerComment(redirectUrl: any) {
    this.router.navigateByUrl(redirectUrl);
  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    let filename = '';

    const maxSize = 20 * 1024 * 1024; // 20MB in bytes
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      if (file.size > maxSize) {
        console.log('File size should be less than 20MB:', file.name);
        this.commonService.openSnackBar('File size should be less than 20MB', '', 'error');
        event.target.value = null;
        return;
      }
      console.log('Valid file:', file.name);
    }

    if (this.controlName === 'cdl')
      filename = 'CDL';

    else if (this.controlName === 'cbs')
      filename = 'BankStatement';

    else if (this.controlName === 'articlesIncorporation')
      filename = 'ArticlesOfInc';

    else if (this.controlName === 'businessValidation')
      filename = 'StateVerification';

    for (const file of this.selectedFiles) {
      this.documents.push({ file: file, type: filename });
    }
    //const transferredFiles = event?.target?.files;
    //if (transferredFiles) {
    //  for (let i = 0; i < transferredFiles.length; i++) {
    //    const file = transferredFiles[i];
    //    this.readFile(file);
    //  }
    //}
  }
  private readFile(file: File) {
    const reader = new FileReader();
    const cdlSelectedVal = this.data.get('cdlVal')?.value as string;
    const cbsSelectedVal = this.data.get('cbs')?.value as string;
    reader.onload = (e: any) => {
      const currentFileObj = {name: file.name,type: file.type,dataUrl: e.target.result,};
      const base64String: string = reader.result as string;
      if (this.controlName == 'cdl') {
        //this.cdlFiles.push({ category: cdlSelectedVal, ...currentFileObj });
      } else if (this.controlName == 'cbs') {
        //this.cbsFiles.push({ category: cbsSelectedVal, ...currentFileObj });
      } else if (this.controlName == 'articlesIncorporation') {
        //this.articlesIncorporationFiles.push({category: 'ArticlesIncorporation',...currentFileObj, });
      } else if (this.controlName == 'businessValidation') {
        //this.businessValidationFiles.push({category: 'BusinessValidation',...currentFileObj,});
      }
    }; reader.readAsDataURL(file);
  }

  openFile() {
    if (this.fileUrl) {
      window.open(this.fileUrl, '_blank');
    }
  }

  triggerFileInput(event: any, label: string) {
    event.preventDefault();
    event.stopPropagation();
    this.controlName = label;
    this.fileInput.nativeElement.click();
  }

  removeUploadedFile(event: any, fileName: any, type: string = '') {
    this.documents = this.documents.filter(file => file.file?.name !== fileName || file.type !== type);
    const target = event.target as HTMLElement;
    const parent = target.closest('.file-link');
    if (parent) parent.remove();    
  }

  data = new FormGroup({
    cdlVal: new FormControl(''),
    cbs: new FormControl(''),
    articlesIncorporation: new FormControl(''),
    businessValidation: new FormControl(''),
  });

  onSubmit() {
    this.commonService.startSpinner();
    if (this.documents.length > 0 && !!this.leadId) {

      this.store.dispatch(new UploadDocuments(this.documents, this.leadId)).subscribe(
        (response: any) => {
          this.documents = [];
          this.commonService.stopSpinner();
          if (response.documents.documents.length > 0)
            this.commonService.openSnackBar('An error occurred during the documents upload.', '', 'error');
          else {
            this.routeOnThanks();
          }            
        },
        (error: any) => {
          this.commonService.stopSpinner();
          //this.commonService.openSnackBar(error.message, '', 'error');
          this.commonService.openSnackBar('A technical error has occurred. Please reach out to the OTR Team for assistance.', '', 'error');
        }
      );
    }
    else {
      this.commonService.stopSpinner();
      this.commonService.openSnackBar("Please select file.", '', 'error')
    }
  }

  routeOnThanks() {
    this.commonService.isRouteFromDocumentScreen = true;
    this.router.navigate(['/documents/thank-you'], {
      //skipLocationChange: true,
    });
  }

}
