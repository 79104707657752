import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnDestroy {

  progressValue: any;
  private subscriptionName: Subscription;

  constructor(private commonService: CommonService, private cdr: ChangeDetectorRef) {

    // subscribe to sender component messages
    this.subscriptionName = this.commonService.getUpdate().subscribe
      (message => {
        this.progressValue = message.text;
      });

  }

  ngOnDestroy() {
    this.subscriptionName.unsubscribe();
  }

}
