import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from '../../../../../services/common.service';

@Component({
  selector: 'app-apply-now-q5',
  templateUrl: './apply-now-q5.component.html',
  styleUrls: ['./apply-now-q5.component.css']
})
export class ApplyNowQ5Component {
  constructor(private router: Router, private ngxService: NgxUiLoaderService
     , private commonService: CommonService
    ) {
    this.commonService.sendUpdate('60%');
  }

  onAnswerClick(value: any) {
    this.commonService.applyNowData.fleetSize = value;
    this.routerComment();
  }

  routerComment() {
    this.router.navigate(['/ApplyNowSubmit'], {
      skipLocationChange: true,
    });
  }

}
