import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Select, Store } from '@ngxs/store';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { Observable, lastValueFrom } from 'rxjs';
import { TruckState } from '../../states/truck/truck.state';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})


export class SliderComponent {
  otrLeasingURL: string;

  @Select(TruckState.GetTrucksData) trucksData$: Observable<any>
  constructor(
    private store: Store,
    private commonService: CommonService,
    private router: Router
  ) {
    this.otrLeasingURL = environment.otrleasing.base_uri;

  } 

  customOptions: OwlOptions = {
    mouseDrag: true,
    loop: true,
    navSpeed: 3,
    autoplay: true,
    dots: true,
    nav: true,
    lazyLoad: true,
    navText: ['<span class="chevron-left"></span>', '<span class="chevron-right"></span>'],
    autoWidth: false,
    responsive: {
      0: {
        items: 1,
        dots: false,
        nav: true,
      },
      400: {
        items: 2,
        dots: false,
        nav: true,
        autoWidth: true
      },
      600: {
        items: 3,
        dots: false,
        nav: true,
        autoWidth: true
      },
      740: {
        items: 5
      },
      1000: {
        items: 6
      },
      1600: {
        items: 7
      } 
    },
  }

  isDisplayViewAllLink: boolean = false; 
  stopSpinner(): void {
    this.isDisplayViewAllLink = true;
  }

  routViewAll(route: string): void {
    window.location.href = this.otrLeasingURL + route;
  }

}
