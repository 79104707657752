import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare var gtag: any;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

  trackEvent(): Observable<any> {
    return new Observable(observer => {
      gtag('get', 'G-4Q8NC9XGZ3', 'client_id', (client_id: any) => {
        observer.next(client_id); // Emit the client ID
        observer.complete();       // Complete the observable
      });
    });
  }
}
