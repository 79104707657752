<div class="slider-container">
  <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/images/slider-truck.png" class="slider-cover-img d-block w-100" alt="...">
        <div class="carousel-caption d-md-block mb-3">
          <img src="assets/images/otr-leasing-logo.png" class="slider-otr-leasing-img d-block w-100" alt="...">

          <h1 class="mb-3">Your Truck is Waiting for You</h1>
          <h6 class="orange">We are not a carrier. We lease Class 8 semi-trucks to owner-operators and small fleet owners.</h6>

          <div class="owl-carousel-loader">
            <div class="loader-page-container">
              <div class="loader-container">
                <div class="loader-wrap">
                  <div class="loader loader-3">
                    <div></div><div></div><div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="owl-carousel-o-truck">

            <owl-carousel-o [options]="customOptions" (initialized)="stopSpinner()">
              <ng-container *ngIf="trucksData$ | async as trucksData">
                <ng-container *ngFor="let truck of trucksData.trucksResponse">
                  <ng-template carouselSlide>
                    <a [href]="otrLeasingURL + 'inventory/' + truck.id">
                      <div class="truck-info">
                        <div class="truck-image-parent">
                          <img [src]="truck.imageUrl" [alt]="truck.year + ' ' + truck.make + ' ' + truck.model" class="truck-image">
                        </div>
                        <div class="truck-description">
                          <h1>{{ truck.year }} {{ truck.make }}</h1>
                          <h1>{{ truck.model }}</h1>
                          <h1 class="truck-inventory">Inventory ({{ truck.location && truck.location != "" ? truck.location : "" }})</h1>
                        </div>
                        <div class="truck-detail">
                          <h3>Stock # {{ truck.vinright6 }}</h3>
                          <h3>{{ truck.formatMileage }} Miles</h3>
                          <h3>Status : {{ truck.status }}</h3>
                        </div>
                      </div>
                    </a>
                  </ng-template>
                </ng-container>
              </ng-container>
            </owl-carousel-o>

            <ng-container *ngIf="isDisplayViewAllLink">
              <p class="view-all-inventory-link orange" (click)="routViewAll('inventory')">View All</p>
            </ng-container>
              

          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img src="assets/images/slider-truck.png" class="slider-cover-img d-block w-100" alt="...">
        <div class="carousel-caption d-md-block">
          <img src="assets/images/otr-leasing-logo.png" class="slider-otr-leasing-img d-block w-100" alt="...">
          <h5 class="mb-3">Get Qualified for Exclusive Access to Incoming Trucks</h5>
          <h1 class="mb-3">Your Truck is Waiting for You</h1>
          <h6 class="orange mb-3">We are not a carrier. We lease Class 8 semi-trucks to owner-operators and small fleet owners.</h6>
          <p>At OTR Leasing, our unique approach to financing sets us apart. We don’t just rely on credit history; we focus on the more important aspects of your professional journey, including your work history and driving experience. Choose to lease your truck through us because we understand the nuances of your career. Fill out our secure application, and within just 1 business day, we will contact you and you’ll be on your way to taking control of your professional path with a lease that’s tailored to your success.</p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="assets/images/slider-truck.png" class="slider-cover-img d-block w-100" alt="...">
        <div class="carousel-caption d-md-block">
          <img src="assets/images/otr-leasing-logo.png" class="slider-otr-leasing-img d-block w-100" alt="...">
          <h5 class="mb-3">Get Qualified for Exclusive Access to Incoming Trucks</h5>
          <h1 class="mb-3">Your Truck is Waiting for You</h1>
          <h6 class="orange mb-3">We are not a carrier. We lease Class 8 semi-trucks to owner-operators and small fleet owners.</h6>
          <p>At OTR Leasing, our unique approach to financing sets us apart. We don’t just rely on credit history; we focus on the more important aspects of your professional journey, including your work history and driving experience. Choose to lease your truck through us because we understand the nuances of your career. Fill out our secure application, and within just 1 business day, we will contact you and you’ll be on your way to taking control of your professional path with a lease that’s tailored to your success.</p>
        </div>
      </div>
    </div>

  </div>
</div>
