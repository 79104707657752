import { Action, State, StateContext } from '@ngxs/store';
import { catchError, tap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { UploadDocuments } from './upload-documents.actions';
import { FileDTO } from './upload-documents.model';


export interface IUploadDocument {
  documents: any | null;
}

@Injectable()
  @State<IUploadDocument>({
    name: 'documents',
    defaults: {
      documents: []
    }
})


export class UploadDocumentsState {
  baseUrl: string;
  constructor(private http: HttpServiceService,
    private common: CommonService) {
    this.baseUrl = environment.applynow.base_uri;
  }


  @Action(UploadDocuments)
  uploadDocuments(ctx: StateContext<any>, action: UploadDocuments) {
    const files = action.files;
    const leadId = action.leadId;
    // Prepare FormData
    const formData = this.prepareFormData(files);
    // Upload documents
    const url = `${this.baseUrl}integration/LeasePath/documents/${leadId}`;
    return this.http.upoadDocsWithToken(url, formData).pipe(
      tap((response: any) => {
        ctx.setState({
          documents: response
        });
      }),
      catchError((error) => {
        //alert('Error occurred: ' + error.message);
        return throwError(error);
      })
    );;
  }

  private prepareFormData(files: FileDTO[]): FormData {
    const formData = new FormData();
    files.forEach((file, index) => {
      if (file.file instanceof Blob) {
        formData.append(`files[${index}].File`, file.file, file.file.name);
        formData.append(`files[${index}].Type`, file.type || '');
      } else {
        console.error('File is not of type Blob:', file.file);
      }
    });
    return formData;
  }
}
