// export namespace ApplyActions{
//   export class Loading {
//     static readonly type = '[Apply] Set As Loading';
//   }

//   export class Working {
//     static readonly type = '[Apply] Set As Working';
//   }

//   export class Done {
//     static readonly type = '[Apply] Set As Done';
//   }


// }


import { ApplyStateModel } from './apply.model';

export class AddApplyNow {
  static readonly type = '[Apply] Add ApplyNow';
  constructor(public payload: ApplyStateModel) {}
}
export class sendPin{
  static readonly type = '[Auth] sendPin';
  constructor(public payload: any) {}
}
export class VerifyCode{
  static readonly type = '[Verification] Verify Code';
  constructor(public phoneNumber: string, public code: string,public leadId:string) { }
}

