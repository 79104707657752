import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../../services/common.service';
import { Store } from '@ngxs/store';
import { sendPin } from 'projects/web/src/app/states/apply/apply.actions';
import { GetLead } from '../../../../../states/leads/leads.actions';


@Component({
  selector: 'app-apply-now-send-code',
  templateUrl: './apply-now-send-code.component.html',
  styleUrls: ['./apply-now-send-code.component.css']
})
export class ApplyNowSendCodeComponent {

  maskPhonePattern = '(000)000-0000';
  id: any;
  constructor(private router: Router, private ngxService: NgxUiLoaderService
    , private commonService: CommonService, private store: Store,
  ) {
    this.commonService.sendUpdate('0%');

  }
  ngOnInit(): void {

    this.commonService.startSpinner();
    let url = window.location.href;
    this.id = this.commonService.getUrlParameter('id', url);

    if (this.id != null && this.id != undefined) {
      this.store.dispatch(new GetLead(this.id)).subscribe(
        (response: any) => {
          this.commonService.leadData = response.leads.leads;
          if (this.commonService.leadData?.mobilePhone)
            this.data.patchValue({ phone: this.commonService.leadData.mobilePhone });

          this.commonService.stopSpinner();
        },
        (error: any) => {
            this.commonService.openSnackBar('The Application ID is not valid. Please reach out to the OTR Leasing team for assistance.', '', 'error');          
          this.commonService.stopSpinner();
        }
      );
    }
  }

  data = new FormGroup({
    phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.maxLength(10), Validators.minLength(10)])
  });

  onSubmit() {

    if (this.data.invalid) {
      this.commonService.openSnackBar('Invalid Data Number', '', 'error');
      return;
    }
    this.commonService.startSpinner();
    try {

      if (this.id == undefined || this.id == null || this.id == '') {
        this.commonService.openSnackBar('Invalid Application Id. Please reach out to the OTR Team for assistance.', '', 'error');
        return;
      }

      this.store.dispatch(new sendPin(
        {
          'phoneNumber': this.data.controls['phone'].value,
          'leadId': this.id
        }
      )).subscribe(
        (response: any) => {
          if (response.applyNow.sendPin.sid != null) {
            this.redirectToVerify();
            this.commonService.stopSpinner();
          }
          else {
            this.commonService.openSnackBar(response.applyNow.sendPin.message, '', 'error');
            this.commonService.stopSpinner();
          }
        },
        (error: any) => {
          this.commonService.openSnackBar('A technical error has occurred. Please reach out to the OTR Team for assistance.', '', 'error');
          this.commonService.stopSpinner();
        }
      );
    } catch (error) {
      this.commonService.openSnackBar('Internal server error...!', '', 'error');
      this.commonService.stopSpinner();
    }
  }

  redirectToVerify() {
    let url = window.location.href;
    let id = this.commonService.getUrlParameter('id', url);

    if (id) {
      this.router.navigate(['/otp/verify'], {
        queryParams: { id: id }
      });
    }
    else {
      this.router.navigateByUrl('/otp/verify');
    }
  }

  redirectToNewUrl() {
    const currentUrl = this.router.url; // Get the current URL
    const queryParams = this.parseQueryParams(currentUrl); // Parse the query parameters
    this.routerComment(queryParams);
  }

  private parseQueryParams(url: string): { [key: string]: string } {
    const paramsIndex = url.indexOf('?');
    if (paramsIndex !== -1) {
      const queryString = url.substring(paramsIndex + 1);
      const queryParamsArray = queryString.split('&');
      const queryParams: { [key: string]: string } = {};
      queryParamsArray.forEach(param => {
        const [key, value] = param.split('=');
        queryParams[key] = decodeURIComponent(value); // Decode the query parameter value
      });
      return queryParams;
    }
    return {};
  }
  routerComment(redirection: any) {
    if (Object.keys(redirection).length) {
      this.commonService.redirectionUrl = redirection;
      this.router.navigate(['/otp/verify'], {
        queryParams: { redirectUrl: redirection }
      });
    }
    else {
      this.router.navigateByUrl('/otp/verify');
    }


  }

}
