import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ApplyStateModel } from '../states/apply/apply.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SnackBarComponent } from '../views/main/components/snack-bar/snack-bar.component';
import { LeadsStateModel } from '../states/leads/leads.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  phoneNumber: any;
  token: string = "";
  redirectionUrl: string = "";
  leadId: string = "";
  leadData: any = <LeadsStateModel>{};
  applyNowData: ApplyStateModel = new ApplyStateModel();
  isRouteFromDocumentScreen: boolean = false;

  constructor(private _snackBar: MatSnackBar, private spinner: NgxUiLoaderService) {
    
  }

  private progresBarDt = new Subject<any>();

  sendUpdate(message: string) {
    this.progresBarDt.next({ text: message });
  }

  getUpdate(): Observable<any> {
    return this.progresBarDt.asObservable();
  }
  getUrlParameter(name: string, url: string): string | null {
    const searchParams = new URLSearchParams(new URL(url).search);
    return searchParams.get(name);
  }

   openSnackBar(message: string, action: string, type: string) {
    const configSuccess = {
      horizontalPosition: 'center' as MatSnackBarHorizontalPosition,
      verticalPosition: 'top' as MatSnackBarVerticalPosition,
      panelClass: ['custom-snackbar', 'cs-success'],
      duration: 10000,
      data: {
        title: 'Success',
        icon: 'done', 
        //items: ['Item 1', 'Item 2', 'Item 3']
        message: message
      }
     };
     const configError = {
       horizontalPosition: 'center' as MatSnackBarHorizontalPosition,
       verticalPosition: 'top' as MatSnackBarVerticalPosition,
       panelClass: ['custom-snackbar','cs-error'],
       duration: 10000,
       data: {
         title: 'Error',
         icon: 'gpp_maybe', 
         //items: ['Item 1', 'Item 2', 'Item 3']
         message: message
       }
     };

     let config: any;
     if (type === 'success')
       config = configSuccess;
     else
       config = configError;

     const snackBarRef = this._snackBar.openFromComponent(SnackBarComponent, config);
  }



  startSpinner() {
    this.spinner.start();
  }
  stopSpinner() {
    this.spinner.stop();
  }
  

}
