<div class="material-dialog-content">

  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="cancel"
            class="m-dialog-cancel-icon" (click)="onNoClick()"></mat-icon>

  <div class="row" style="margin-top: 1.9rem;">
    <!--<div class="col col-lg-12 col-md-12 col-sm-12 text-center">
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle value="bold">Both</mat-button-toggle>
        <mat-button-toggle value="italic">New</mat-button-toggle>
        <mat-button-toggle value="underline">Used</mat-button-toggle>
      </mat-button-toggle-group>
    </div>-->
  </div>


  <div mat-dialog-content>

    <div class="row">

      <div class="col col-lg-4 col-md-6 col-sm-6">
        <mat-label>Truck Type</mat-label>
        <mat-form-field class="example-full-width">

          <mat-select matNativeControl placeholder="Any Truck Type">
            <mat-option value="two">Any Truck Type</mat-option>
            <mat-optgroup label="Semi Trucks">
              <mat-option value="volvo">All Semi Trucks</mat-option>
              <mat-option value="saab">Day Cabs</mat-option>
              <mat-option value="saab">Haulers</mat-option>
              <mat-option value="saab">Sleeper Trucks</mat-option>
            </mat-optgroup>
            <mat-optgroup label="Box Trucks">
              <mat-option value="mercedes">All Box Trucks</mat-option>
              <mat-option value="mercedes">Box Vans</mat-option>
              <mat-option value="audi">Cargo Vans</mat-option>
            </mat-optgroup>
          </mat-select>

        </mat-form-field>
      </div>
      <div class="col col-lg-4 col-md-6 col-sm-6">
        <mat-label>Make</mat-label>
        <mat-form-field class="example-full-width">
          <mat-select placeholder="Any Make">
            <mat-option value="one">Any Make</mat-option>
            <mat-option value="two">0680600 - HULT</mat-option>
            <mat-option value="two">5212048 - ROTAT</mat-option>
            <mat-option value="two">862253-DMF - 16</mat-option>
            <mat-option value="two">Chevrolet</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-lg-4 col-md-6 col-sm-6">
        <mat-label>Model</mat-label>
        <mat-form-field class="example-full-width">
          <mat-select placeholder="Any Truck Model">
            <mat-option value="one">Any Truck Model</mat-option>
            <mat-option value="two">114SD</mat-option>
            <mat-option value="two">379</mat-option>
            <mat-option value="two">389</mat-option>
            <mat-option value="two">47X</mat-option>
            <mat-option value="two">5700XE</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col col-lg-4 col-md-6 col-sm-6">
        <mat-label>Year</mat-label>
        <mat-form-field class="example-full-width">
          <!--<input matInput placeholder="YYYY" [matDatepicker]="picker">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>-->

          <input matInput placeholder="YYYY"
                 [matDatepicker]="date" readonly>
          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date startView="multi-year"></mat-datepicker>

        </mat-form-field>
      </div>
      <div class="col col-lg-4 col-md-6 col-sm-6">
        <mat-label>Zip</mat-label>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Zip">
        </mat-form-field>
      </div>
      <div class="col col-lg-4 col-md-6 col-sm-6">
        <mat-label>Distance</mat-label>
        <mat-form-field class="example-full-width">
          <mat-select placeholder="Any Distance">
            <mat-option value="one">Any Distance</mat-option>
            <mat-option value="two">50 miles</mat-option>
            <mat-option value="two">100 miles</mat-option>
            <mat-option value="two">200 miles</mat-option>
            <mat-option value="two">300 miles</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="row justify-content-md-center mt-4">
      <div class="col col-lg-6">
        <button mat-stroked-button color="primary">Search</button>
      </div>
    </div>

    <div>
      <div class="row mt-4">
        <div class="col col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <mat-card class="example-card">
            <img mat-card-image src="assets/images/truck1.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p class="c-desc mt-3">{{data.name}}</p>
              <div>
                <p class="c-miles">Miles: 545,204</p>
                <p class="c-amt orange">$39,950</p>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-flat-button color="primary" class="c-select-btn" [mat-dialog-close]="data">Select</button>
              <button mat-flat-button color="accent">View Detail</button>
            </mat-card-actions>
          </mat-card>
        </div>

        <div class="col col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <mat-card class="example-card">
            <img mat-card-image src="assets/images/truck2.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p class="c-desc mt-3">2018 PETERBILT 579 - SLEEPER TRUCK</p>
              <div>
                <p class="c-miles">Miles: 545,204</p>
                <p class="c-amt orange">$39,950</p>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-flat-button color="primary" class="c-select-btn" [mat-dialog-close]="data">Select</button>
              <button mat-flat-button color="accent">View Detail</button>
            </mat-card-actions>
          </mat-card>
        </div>

        <div class="col col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <mat-card class="example-card">
            <img mat-card-image src="assets/images/truck3.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p class="c-desc mt-3">2018 PETERBILT 579 - SLEEPER TRUCK</p>
              <div>
                <p class="c-miles">Miles: 545,204</p>
                <p class="c-amt orange">$39,950</p>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-flat-button color="primary" class="c-select-btn" [mat-dialog-close]="data">Select</button>
              <button mat-flat-button color="accent">View Detail</button>
            </mat-card-actions>
          </mat-card>
        </div>

        <div class="col col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <mat-card class="example-card">
            <img mat-card-image src="assets/images/truck5.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p class="c-desc mt-3">2018 PETERBILT 579 - SLEEPER TRUCK</p>
              <div>
                <p class="c-miles">Miles: 545,204</p>
                <p class="c-amt orange">$39,950</p>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-flat-button color="primary" class="c-select-btn" [mat-dialog-close]="data">Select</button>
              <button mat-flat-button color="accent">View Detail</button>
            </mat-card-actions>
          </mat-card>
        </div>

        <div class="col col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <mat-card class="example-card">
            <img mat-card-image src="assets/images/truck3.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p class="c-desc mt-3">2018 PETERBILT 579 - SLEEPER TRUCK</p>
              <div>
                <p class="c-miles">Miles: 545,204</p>
                <p class="c-amt orange">$39,950</p>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-flat-button color="primary" class="c-select-btn" [mat-dialog-close]="data">Select</button>
              <button mat-flat-button color="accent">View Detail</button>
            </mat-card-actions>
          </mat-card>
        </div>

        <div class="col col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <mat-card class="example-card">
            <img mat-card-image src="assets/images/truck6.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p class="c-desc mt-3">2018 PETERBILT 579 - SLEEPER TRUCK</p>
              <div>
                <p class="c-miles">Miles: 545,204</p>
                <p class="c-amt orange">$39,950</p>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-flat-button color="primary" class="c-select-btn" [mat-dialog-close]="data">Select</button>
              <button mat-flat-button color="accent">View Detail</button>
            </mat-card-actions>
          </mat-card>
        </div>

        <div class="col col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <mat-card class="example-card">
            <img mat-card-image src="assets/images/truck1.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p class="c-desc mt-3">2018 PETERBILT 579 - SLEEPER TRUCK</p>
              <div>
                <p class="c-miles">Miles: 545,204</p>
                <p class="c-amt orange">$39,950</p>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-flat-button color="primary" class="c-select-btn" [mat-dialog-close]="data">Select</button>
              <button mat-flat-button color="accent">View Detail</button>
            </mat-card-actions>
          </mat-card>
        </div>

        <div class="col col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <mat-card class="example-card">
            <img mat-card-image src="assets/images/truck2.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p class="c-desc mt-3">2018 PETERBILT 579 - SLEEPER TRUCK</p>
              <div>
                <p class="c-miles">Miles: 545,204</p>
                <p class="c-amt orange">$39,950</p>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-flat-button color="primary" class="c-select-btn" [mat-dialog-close]="data">Select</button>
              <button mat-flat-button color="accent">enView Detail</button>
            </mat-card-actions>
          </mat-card>
        </div>


      </div>
    </div>

  </div>

</div>

<!--<h1 mat-dialog-title>Hi {{data.name}}</h1>
<div mat-dialog-content>
  <p>What's your favorite animal?</p>
  <mat-label>Favorite Animal</mat-label>
  <mat-form-field>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field>

  <mat-label>Favorite Animal</mat-label>
  <mat-form-field>
    <input matInput [(ngModel)]="data.name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>-->
