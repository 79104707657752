<h1>How Many Months?</h1>

<form class="example-form" [formGroup]="data" (ngSubmit)="onSubmit()">
  <div class="row justify-content-md-center mt-2">
    <div class="col col-lg-6 mt-5">

      <div class="input-group mb-3">
        <input matInput type="number" min="0" max="100" formControlName="llcLength" class="form-control" placeholder="" aria-describedby="basic-addon2"
               oninput="this.value=!!this.value && Math.abs(this.value)>=0?Math.abs(this.value):null">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary input-group-btn" type="submit">
            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="east"></mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="data.get('llcLength')?.invalid && data.get('llcLength')?.touched">
        <mat-error *ngIf="data.get('llcLength')?.errors?.['required']">Months is required.</mat-error>
        <mat-error *ngIf="data.get('llcLength')?.errors?.['pattern']">Months must be a valid number.</mat-error>
        <mat-error *ngIf="data.get('llcLength')?.errors?.['max']">Months must be 100 or less.</mat-error>
      </div>
        

      </div>
    </div>

</form>
