import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { ApplyNowQ1Component } from './apply-now-q1/apply-now-q1.component';
import { ApplyNowQ2Component } from './apply-now-q2/apply-now-q2.component';
import { ApplyNowQ3Component } from './apply-now-q3/apply-now-q3.component';
import { ApplyNowQ4Component } from './apply-now-q4/apply-now-q4.component';
import { ApplyNowQ5Component } from './apply-now-q5/apply-now-q5.component';
import { ApplyNowSubmitComponent } from './apply-now-submit/apply-now-submit.component';
import { ApplyNowThanksComponent } from './apply-now-thanks/apply-now-thanks.component';
import { ApplyNowSendCodeComponent } from './apply-now-send-code/apply-now-send-code.component';
import { ApplyNowVerificationCodeComponent } from './apply-now-verification-code/apply-now-verification-code.component';
import { ApplyNowUploadDocumentComponent } from './apply-now-upload-document/apply-now-upload-document.component';

// import { OTRLeasingLibModule } from 'OTRLeasing.Lib';
import { SelectTruckDialogComponent } from './apply-now-submit/select-truck-dialog/select-truck-dialog.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';

import {HttpClientModule} from'@angular/common/http';
import { MaterialModule } from '../../../../modules/material/material.module';
import { SnackBarComponent } from '../../components/snack-bar/snack-bar.component';
import { DateMaskDirective } from '../../../../directives/date-mask.directive';


const routes: Routes = [
  { path: 'apply-now', component: ApplyNowQ1Component },
  { path: 'ApplyNowQ2', component: ApplyNowQ2Component },  
  { path: 'ApplyNowQ3', component: ApplyNowQ3Component },  
  { path: 'ApplyNowQ4', component: ApplyNowQ4Component },  
  { path: 'ApplyNowQ5', component: ApplyNowQ5Component },  
  { path: 'ApplyNowSubmit', component: ApplyNowSubmitComponent },    
  { path: 'apply-now/thank-you', component: ApplyNowThanksComponent },
  { path: 'documents/thank-you', component: ApplyNowThanksComponent },
  { path: 'otp', component: ApplyNowSendCodeComponent },  
  { path: 'otp/verify', component: ApplyNowVerificationCodeComponent },  
  { path: 'documents/upload', component: ApplyNowUploadDocumentComponent },  
]

@NgModule({
  declarations: [
    ApplyNowQ1Component,
    ApplyNowQ2Component,
    ApplyNowQ3Component,
    ApplyNowQ4Component,
    ApplyNowQ5Component,
    ApplyNowSubmitComponent,
    ApplyNowThanksComponent,
    ApplyNowSendCodeComponent,
    ApplyNowVerificationCodeComponent,
    ApplyNowUploadDocumentComponent,
    SelectTruckDialogComponent,
    SnackBarComponent,
    DateMaskDirective
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,
    RouterModule.forChild(routes),
    MaterialModule,
    // OTRLeasingLibModule,
    NgxCaptchaModule,
    NgxGpAutocompleteModule,
    NgxMaskDirective, NgxMaskPipe,
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [provideNgxMask(),
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyDEq-9vJqYuh2Z-lOj4SICDHjcD0y09LdI',
        libraries: ['places']
      })
    },
  ]
})
export class ApplyNowModule { }
