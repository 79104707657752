import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { TruckStateModel } from '../states/truck/truck.model';
import { AuthState } from '../states/auth/auth.state';
import { Select } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class HttpServiceService {

  @Select(AuthState.getOtrToken) token$: Observable<string>
  constructor(private http: HttpClient) { }

  postData(url: any, paylaod: any): Observable<any> {
    var response = this.http.post<any>(url, paylaod);
    return response;
  }

  postDataWithToken(url: any, payload: any): Observable<any> {
    return new Observable(observer => {
      this.token$.subscribe(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
        const options = { headers: headers };
        this.http.post<any>(url, payload, options).subscribe(
          response => {
            observer.next(response); 
            observer.complete(); 
          },
          error => {
            observer.error(error); 
          }
        );
      });
    });
  }

  GetById(url: any): Observable<TruckStateModel> {
    let response = this.http.get<TruckStateModel>(url);
    return response;
  }

  GetByIdWithToken(url: any): Observable<TruckStateModel> {

    return new Observable(observer => {
      this.token$.subscribe(token => {

        if (token == undefined)
          return;

        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
        const options = { headers: headers };
        this.http.get<TruckStateModel>(url, options).subscribe(
          response => {
            observer.next(response);
            observer.complete();
          },
          error => {
            observer.error(error);
          }
        );
      });
    });
  }

  upoadDocs(url: any, data: any) {
    return this.http.post(url, data);
  }

  upoadDocsWithToken(url: any, data: any) {

    return new Observable(observer => {
      this.token$.subscribe(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
        const options = { headers: headers };
        this.http.post(url, data, options).subscribe(
          response => {
            observer.next(response);
            observer.complete();
          },
          error => {
            observer.error(error);
          }
        );
      });
    });
  }
}
