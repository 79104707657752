<!-- Progress Bar -->
<div class="row justify-content-md-center progress-bar-row">
  <div class="col-xl-7 col-lg-10 col-md-12 col-sm-12">
    <div class="progress-container">
      <div class="progress-truck-img-container">
        <img src="assets/images/progress-truck.png" class="progress-truck-img" alt="..." style="left:{{ progressValue }}">
      </div>
      <div class="progress">
        <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:{{progressValue}}">
        </div>
      </div>
    </div>
  </div>
</div>
