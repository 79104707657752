<h1>Verification</h1>
<p class="mt-3 text-center">You will get an OTP via SMS</p>

<form class="example-form" [formGroup]="data" (ngSubmit)="onSubmit()">
<div class="row justify-content-md-center mt-5">
  <div class="col col-lg-7 col-xl-6">
    <mat-form-field class="example-full-width">
      <input matInput type="text" placeholder="" formControlName="pin" class="text-center">
      <mat-error>Please Enter 6 digit Code</mat-error>
    </mat-form-field>
    </div>
  </div>
  <div class="row justify-content-md-center mt-2">
    <div class="col col-lg-7 col-xl-6">
      <button mat-stroked-button color="primary" type="submit">Verify</button>
      
      <p style="cursor: pointer;" class="mt-5 text-center" (click)="resendAgain()">Didn't receive the verification OTP? <span class="resend-again-txt">Resend again</span></p>
      
    </div>
  </div>
</form>
