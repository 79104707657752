<div class="row m-0">
  <div class="col-lg-3 col-md-12 col-sm-12">

  </div>
  <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
    <!--<div class="stepper-wrapper">
      <div class="stepper-item completed">
        <div class="step-counter" (click)="handleClick($event, '/apply-now')"></div>
      </div>
      <div class="stepper-item">
        <div class="step-counter" (click)="handleClick($event, '/ApplyNowQ2')"></div>
      </div>
      <div class="stepper-item">
        <div class="step-counter" (click)="handleClick($event, '/ApplyNowQ3')"></div>
      </div>
      <div class="stepper-item">
        <div class="step-counter" (click)="handleClick($event, '/ApplyNowQ4')"></div>
      </div>
      <div class="stepper-item">
        <div class="step-counter" (click)="handleClick($event, '/ApplyNowQ5')"></div>
      </div>
      <div class="stepper-item">
        <div class="step-counter" (click)="handleClick($event, '/ApplyNowSubmit')"></div>
      </div>
    </div>-->
    
  </div>
  <div class="col-lg-3 col-md-12 col-sm-12 reload-tag mb-3">
    <a href="#">
      Start Over
      <mat-icon fontIcon="refresh"></mat-icon>
    </a>
  </div>
</div>
<footer class="footer">
  <div class="row m-0">
    <div class="col-lg-12 text-center">
      <p>© OTR Leasing 2024</p>
    </div>
  </div>
</footer>
