import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxsModule } from '@ngxs/store';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';
import { SharedModule } from "../app/modules/shared/shared.module";
import {HttpClientModule} from'@angular/common/http';
import { ApplyNowState } from './states/apply/apply.state';
import { TruckState } from './states/truck/truck.state';
import { AuthState } from './states/auth/auth.state';
import { UploadDocumentsState } from './states/upload-documents/upload-documents.state';
import { LeadsState } from './states/leads/leads.state';
import { CarouselModule } from 'ngx-owl-carousel-o';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#FF6720",
  "bgsOpacity": 0.5,
  "bgsPosition": "center-center",
  "bgsSize": 60,
  "bgsType": "rectangle-bounce",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#ffffff",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "rectangle-bounce",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40,40,40,0.8)",
  "pbColor": "#FF6720",
  "pbDirection": "ltr",
  "pbThickness": 8,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    SharedModule,
    NgxGpAutocompleteModule,
    NgxsModule.forRoot([
      ApplyNowState,
      TruckState,
      UploadDocumentsState,
      LeadsState,
      AuthState
    ]),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    //NgxUiLoaderHttpModule.forRoot({ "showForeground": true }),
    NgxMaskDirective, NgxMaskPipe,
    CarouselModule  
  ],
  providers: [provideNgxMask(), 
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyDEq-9vJqYuh2Z-lOj4SICDHjcD0y09LdI',
        libraries: ['places']
      })
    },
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
