import { tap, catchError, map } from 'rxjs/operators';
import { StateContext, Action, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from 'projects/web/src/environments/environment';
import { GetTruckById, GetTrucks } from './truck.action';
import { TruckStateModel } from './truck.model';
import { CommonService } from '../../services/common.service';

@Injectable()
export class TruckState {
  baseUrl: string;
  baseLeasePathUrl: string;
  constructor(private http: HttpServiceService,
    private store: Store,
    private commonService: CommonService) {
    this.baseUrl = environment.applynow.base_uri;
    this.baseLeasePathUrl = environment.lease_path.base_uri;
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.store.dispatch(new GetTrucks());
  }

  @Action(GetTruckById)
  getTruckById(ctx: StateContext<TruckStateModel>, truck: GetTruckById) {
    let url = `${this.baseUrl}integration/Truck/${truck.id}`;
    return this.http.GetByIdWithToken(url).pipe(
      tap((response: any) => {
        ctx.setState({truckResponse:response});
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }


  @Selector()
  static GetTrucksData(state: any): any {
    return state;
  }

  @Action(GetTrucks)
  getTrucks(ctx: StateContext<TruckStateModel>) {
    let url = `${this.baseLeasePathUrl}/Trucks`;
    return this.http.GetById(url).pipe(
      tap((response: any) => {
        ctx.setState({ trucksResponse: response });
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
