import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from '../../../../../services/common.service';

@Component({
  selector: 'app-apply-now-q3',
  templateUrl: './apply-now-q3.component.html',
  styleUrls: ['./apply-now-q3.component.css']
})
export class ApplyNowQ3Component {

  constructor(private router: Router, private ngxService: NgxUiLoaderService
     , private commonService: CommonService
    ) {
     this.commonService.sendUpdate('30%');

  }

  onAnswerClick(value: any) {
    this.commonService.applyNowData.llcOwnerShip = value;
    this.routerComment(value);
  }

  routerComment(value: any) {
    let routerUrl = '/ApplyNowSubmit';
    if (value == true)
      routerUrl = '/ApplyNowQ4';

    this.router.navigate([routerUrl],{
      skipLocationChange: true,
    });
  }

}
