<div class="document-upload-form-container">

  <div class="stepper-wrapper">
    <div class="stepper-item completed" [class.active]="step === 1" [class.completed]="step > 1">
      <div class="step-counter"></div>
      <div class="step-name">Applied</div>
    </div>
    <div class="stepper-item active" [class.active]="step === 2" [class.completed]="step > 2">
      <div class="step-counter"></div>
      <div class="step-name">Contacted</div>
    </div>
    <div class="stepper-item" [class.active]="step === 3" [class.completed]="step > 3">
      <div class="step-counter"></div>
      <div class="step-name">Need Documents</div>
    </div>
    <div class="stepper-item" [class.active]="step === 4" [class.completed]="step > 4">
      <div class="step-counter"></div>
      <div class="step-name">Credit Submitted (25Hrs)</div>
    </div>
    <div class="stepper-item" [class.active]="step === 5" [class.completed]="step > 5">
      <div class="step-counter"></div>
      <div class="step-name">Credit Approved</div>
    </div>
    <div class="stepper-item" [class.active]="step === 6" [class.completed]="step > 6">
      <div class="step-counter"></div>
      <div class="step-name">Deposit Secured</div>
    </div>
    <div class="stepper-item" [class.active]="step === 7" [class.completed]="step > 7">
      <div class="step-counter"></div>
      <div class="step-name">Documents Sent</div>
    </div>
    <div class="stepper-item" [class.active]="step === 8" [class.completed]="step > 8">
      <div class="step-counter"></div>
      <div class="step-name">Seated</div>
    </div>
  </div>
  <h1>Welcome {{firstName}} to your credit application!</h1>
  <div class="row mt-5 upload-doc-cont-detail-row">
    <div class="col col-sm-12 col-md-12 col-lg-4 mb-3">
      <div class="upload-doc-container-title"><mat-icon>cloud_upload</mat-icon> <span>Upload Documents</span></div>
    </div>
    <div class="col col-sm-12 col-md-12 col-lg-4 text-center mb-3">
      <div>FILE TYPE: <span style="color: darkgray">PDF, JPEG, PNG, DOC</span></div>
    </div>
    <div class="col col-sm-12 col-md-12 col-lg-4 upload-doc-container-filesize mb-3">
      <div><small>Maximum file size is 20MB</small></div>
    </div>
  </div>
  <form class="example-form" [formGroup]="data" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col col-lg-12 col-xl-12">
        <div class="drop-container">
          <div class="row">
            <div class="col col-lg-6 col-md-6 col-sm-6">
              <mat-label>Applicant's CDL or DL</mat-label>
              <mat-form-field class="example-full-width">
                <input matInput type="text" formControlName="cdlVal" placeholder="" [readonly]="true">
                <button matSuffix mat-icon-button class="input-upload-btn" (click)="triggerFileInput($event, 'cdl')">
                  <mat-icon>cloud_upload</mat-icon>
                </button>
              </mat-form-field>
              <div *ngFor="let file of documents; let i = index">
                <p *ngIf="file.type === 'CDL'" class="file-link">
                  <span>{{ file.file?.name }}</span>
                  <mat-icon (click)="removeUploadedFile($event, file.file?.name, file.type)">cancel</mat-icon>
                </p>
              </div>

            </div>
            <div class="col col-lg-6 col-md-6 col-sm-6">
              <mat-label>Bank Statement</mat-label>
              <mat-form-field class="example-full-width">
                <input matInput type="text" formControlName="cbs" placeholder="" [readonly]="true">
                <button matSuffix mat-icon-button class="input-upload-btn" (click)="triggerFileInput($event, 'cbs')">
                  <mat-icon>cloud_upload</mat-icon>
                </button>
              </mat-form-field>

              <div *ngFor="let file of documents; let i = index">
                <p *ngIf="file.type === 'BankStatement'" class="file-link">
                  <span>{{ file.file?.name }}</span>
                  <mat-icon (click)="removeUploadedFile($event, file.file?.name, file.type)">cancel</mat-icon>
                </p>
              </div>
            </div>
            <div class="col col-lg-12 col-md-12 col-sm-12">
              <mat-label>Additional documents if applying as a business:</mat-label>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-6">
              <mat-label>Articles of Incorporation</mat-label>
              <mat-form-field class="example-form-field">
                <input matInput type="text" formControlName="articlesIncorporation" placeholder="" [readonly]="true">
                <button matSuffix mat-icon-button class="input-upload-btn" (click)="triggerFileInput($event, 'articlesIncorporation')">
                  <mat-icon>cloud_upload</mat-icon>
                </button>
              </mat-form-field>

              <div *ngFor="let file of documents; let i = index">
                <p *ngIf="file.type === 'ArticlesOfInc'" class="file-link">
                  <span>{{ file.file?.name }}</span>
                  <mat-icon (click)="removeUploadedFile($event, file.file?.name, file.type)">cancel</mat-icon>
                </p>
              </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-6">
              <mat-label>Business Validation via Secretary of State</mat-label>
              <mat-form-field class="example-form-field">
                <input matInput type="text" formControlName="businessValidation" placeholder="" [readonly]="true">
                <button matSuffix mat-icon-button class="input-upload-btn" (click)="triggerFileInput($event, 'businessValidation')">
                  <mat-icon>cloud_upload</mat-icon>
                </button>
              </mat-form-field>

              <div *ngFor="let file of documents; let i = index">
                <p *ngIf="file.type === 'StateVerification'" class="file-link">
                  <span>{{ file.file?.name }}</span>
                  <mat-icon (click)="removeUploadedFile($event, file.file?.name, file.type)">cancel</mat-icon>
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <input accept=".pdf,.png,.jpeg,.jpg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     type="file" #fileInput id="fileInput" style="display: none" multiple (change)="onFileSelected($event)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center mt-5">
      <div class="col col-lg-7 col-xl-6">
        <button mat-stroked-button color="primary">Submit</button>
      </div>
    </div>
  </form>


</div>
