import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from '../../../../../services/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { VerifyCode, sendPin } from 'projects/web/src/app/states/apply/apply.actions';

@Component({
  selector: 'app-apply-now-verification-code',
  templateUrl: './apply-now-verification-code.component.html',
  styleUrls: ['./apply-now-verification-code.component.css']
})
export class ApplyNowVerificationCodeComponent{

  leadId:any;

  constructor(private router: Router, private ngxService: NgxUiLoaderService
    , private commonService: CommonService, private store: Store,
  ) {
    this.commonService.sendUpdate('30%');
  }
  ngOnInit(): void {
    let url = window.location.href;
    this.leadId = this.commonService.getUrlParameter('id', url);
  }
  data = new FormGroup({
    pin: new FormControl('', [Validators.required])
  });

  routerComment() {

    let url = window.location.href;
    let id = this.commonService.getUrlParameter('id', url);
    if (id) {
      this.router.navigate(['/documents/upload'], {
        queryParams: { id: id }
      });
    }
    else {
      this.router.navigateByUrl('/documents/upload');
    }
    //if (this.commonService.redirectionUrl) {
    //  this.router.navigate(['/documents/upload'], {
    //    queryParams: { redirectUrl: this.commonService.redirectionUrl }
    //  });
    //}
    //else {
    //  this.router.navigateByUrl('/documents/upload');
    //}
  }

  onSubmit() {
    if (this.data.valid) {
      this.commonService.startSpinner();
      try {
        this.store.dispatch(new VerifyCode(this.commonService.leadData.mobilePhone, this.data.controls['pin'].value ?? '',this.leadId)).subscribe(
          (response: any) => {
            if (response.applyNow.verifyCode.sid != null) {
              this.commonService.token = response.applyNow.verifyCode.sid;
              this.routerComment();
              this.commonService.stopSpinner();
            }
            else {
              this.commonService.stopSpinner();
              this.commonService.openSnackBar('Verification Failed. The OTP you entered is incorrect', '', 'error');
            }
          },
          (error: any) => {
            this.commonService.stopSpinner();
            this.commonService.openSnackBar('A technical error has occurred. Please reach out to the OTR Team for assistance.', '', 'error');
          }
        );
      } catch (error) {
        this.commonService.stopSpinner();
        this.commonService.openSnackBar('Internal server error...!', '', 'error');
      }
    }
  }

  resendAgain() {
    this.commonService.startSpinner();
    try {
      this.store.dispatch(new sendPin(
        {
          'phoneNumber': this.commonService.leadData.mobilePhone,
          'leadId': this.leadId
        })).subscribe(
        (response: any) => {
          if (response.applyNow.sendPin.sid != null) {
            this.commonService.stopSpinner();
            this.commonService.openSnackBar(response.applyNow.sendPin.message, '', 'success');
          }
          else {
            this.commonService.stopSpinner();
            this.commonService.openSnackBar(response.applyNow.sendPin.message, '', 'error');
          }
        },
        (error: any) => {
          this.commonService.stopSpinner();
          this.commonService.openSnackBar('A technical error has occurred. Please reach out to the OTR Team for assistance.', '', 'error');
        }
      );
    } catch (error) {
      this.commonService.stopSpinner();
      this.commonService.openSnackBar('Internal server error...!', '', 'error');
    }
  }


}
